import * as React from 'react';
import { styled } from '@mui/material/styles';
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

//ROM

import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
//import PaymentMethod from "layouts/billing/components/PaymentMethod";
//import Invoices from "layouts/billing/components/Invoices";
//import BillingInformation from "layouts/billing/components/BillingInformation";
//import Transactions from "layouts/billing/components/Transactions";

//ROM:
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import { useState, useEffect} from 'react'
import appconfig from "appconfig";
import useToken from 'useToken';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
//import Files from 'layouts/reviews/edit/components/Files'
//import DataTable from "examples/Tables/DataTable";
//import filesTableData from "layouts/reviews/edit/components/Files/data/";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
//import MenuItem from "@mui/material/MenuItem";
//import { Select, MenuItem } from '@material-ui/core';
//import ClientsMenu from "../components/ClientsMenu";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import {Progress} from 'reactstrap';
import inputOutlined from 'assets/theme/components/form/inputOutlined';


async function getTestData(id,token) {
  //alert(`55: submitData(input_data= ${JSON.stringify(input_data)} token= ${JSON.stringify(token)})`);
  const post_data = { session:{sessionId: token}, data:{id:id}}
  //alert(`57: post_data= ${JSON.stringify(post_data,null,4)}`)
  return fetch(`${appconfig.api_root}/cases/gettestdata`
  , {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(post_data)
    
  }
  )
    .then(data => data.json())
 }

async function deleteData(input_data, token) {
  //alert(`55: submitData(input_data= ${JSON.stringify(input_data)} token= ${JSON.stringify(token)})`);
  const post_data = { session:{sessionId: token}, data:{id:input_data.id}}
  //alert(`57: post_data= ${JSON.stringify(post_data,null,4)}`)
  return fetch(`${appconfig.api_root}/cases/trash`
  , {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(post_data)
    
  }
  )
    .then(data => {
      
      return data.json();
    })
 }

 async function removeFile(fpath,ref_num,claim_num ,token) {
  //alert(`55: submitData(input_data= ${JSON.stringify(input_data)} token= ${JSON.stringify(token)})`);
  
  const post_data = { session:{sessionId: token}, data:{ref_num:ref_num,claim_num:claim_num, fpath:fpath}}
  //alert(`57: post_data= ${JSON.stringify(post_data,null,4)}`)
  return fetch(`${appconfig.api_root}/cases/removefile`
  , {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(post_data)
    
  }
  )
    .then(data => {
      
      return data.json();
    })
 }

 async function updateData(input_data, token) {
  //alert(`55: submitData(input_data= ${JSON.stringify(input_data)} token= ${JSON.stringify(token)})`);
  const post_data = { session:{sessionId: token}, data:input_data}
  //alert(`57: post_data= ${JSON.stringify(post_data,null,4)}`)
  return fetch(`${appconfig.api_root}/cases/postedit`
  , {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(post_data)
    
  }
  )
    .then(data => data.json())
 }


function EditCase(props) {

  const location = useLocation();
  const navigate = useNavigate();
  const {token,setToken} = useToken();
  //alert(`77: AddCase() token= ${JSON.stringify(token)}`);

  if(!token){
    //alert('80: AddCase() !token');
    return (<Navigate to="/authentication/sign-in" />);
  }
  
  //const [inputs,setInputs] = useState({isloading:true,data:{}});
  const [init,setInit] = useState({done:false});
  
  //const [inputs,setData] = useState({});
  const [inputs,setInputs] = useState({});
  const [clients,setClients] = useState([]);
  const [doctors,setDoctors] = useState([]);
  const [files,setFiles] = useState([]);
  const [levels,setLevels] = useState([]);
  const [uploadfile,setUploadFile] = useState({selectedFile: null,loaded: 0});
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const [isuploading, setIsUploading] = useState(false);
  const [isdeleting, setIsDeleting] = useState(false);
  const [caseid,setCaseId] = useState(props.isnew?"0":location.state.id);
  const [errors, setErrors] = useState([]);
  
  
  
  

  //const [files,setFiles] = useState({files: []});
  

  

useEffect(() => {
  //alert('52: useEffect');
  const post_data = { 
    session:{sessionId: token.token}, 
    data:{id: caseid}
  };
  
  
  fetch(`${appconfig.api_root}/cases/getedit`
  , {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(post_data)
    
  }
  )
    .then((res) => res.json())
    .then((jsondata) => {
      //alert(`163: ${JSON.stringify(jsondata,null,4)}`);
      setInit({done: true});
      setInputs(jsondata.ca);
      setClients(jsondata.clients);
      setDoctors(jsondata.doctors);
      setFiles(jsondata.files);
      setLevels(jsondata.levels);
      //setUploadFile({selectedFile: null});
      //setDense(false);
      //setSecondary(false);
    });
}, []);

if(!init.done){
  return (
   <DashboardLayout>
     <DashboardNavbar />
     <MDBox pt={6} pb={3}>
       <p>Loading...</p>
     </MDBox>
     
     <Footer />
   </DashboardLayout>
  );
}



//const { columns: pColumns, rows: pRows } =  filesTableData(tabledata.data.files, navigate);

const handleChange = (event) => {
  const name = event.target.name;
  const value = event.target.value;
  //alert(`name= ${name} value= ${value}`)
  /*if(name == 'doctor'){
    const selected_doctor = doctors.find(d => d.id==value)
    setInputs(values => ({...values, doctor:{...values.doctor,} [name]: value}))
  
  }
  else{*/
    setInputs(values => ({...values, [name]: value}))
  //}
}

const onUploadFileChangeHandler = (event) => {

  //console.log(event.target.files[0])
  //alert('202');
  setUploadFile({
    selectedFile: event.target.files[0],
    loaded: 0,
  });

}

const onUploadFileClickHandler = () => {
  let error_message = [];
  if(!inputs.ref_num){
    error_message.push('REF# is required.');
  }
  if(!inputs.claim_num){
    error_message.push('CLAIM # is required.');
  }
  if(uploadfile.selectedFile == null){
    error_message.push('No file selected.');
  }
  if(error_message.length > 0){
    setErrors(error_message);
    return;
  }
  else{
    if(errors.length>0){
      setErrors([]);
    }
  }
  setIsUploading(true);
  const data = new FormData()
  data.append('attachment', uploadfile.selectedFile,uploadfile.selectedFile.originalname)
  
  data.append('refnum', inputs.ref_num)
  data.append('claimnum', inputs.claim_num)
  data.append('token', token.token)
  //axios.post("https://localhost:8000/upload", data, { 
    axios.post(`${appconfig.upload_root}/uploads`, data, { 
     // receive two    parameter endpoint url ,form data
     onUploadProgress: ProgressEvent => {
      setUploadFile({
         loaded: (ProgressEvent.loaded /ProgressEvent.total*100)
        })
     }
 })
.then(res => { // then print response status
   //console.log(res.statusText)
   //alert(`250: ${JSON.stringify(res)}`);
   
   //const {statusCode,message} = res;
   if(res.data.statusCode == 200 && res.data.file.filename.length > 0){
      //alert(`Upload success!`);
     
      setFiles([...files,{filename:res.data.file.filename,path:res.data.file.path}])
   }
   setIsUploading(false);
})
};

const handleUpdate = async e => {
   //alert('226: handleSubmit');
   e.preventDefault();
   //setInputs({isloading:false, data:input})
   setInit({done: false});
   
   const ret = await updateData(inputs,token.token);
   //alert(`138: handleSubmit() retAddReview= ${JSON.stringify(ret)}`);
  //alert(`232: handleSubmit ret= ${JSON.stringify(ret,null,4)}`);
  if(ret && ret.level_before){
    //alert('142: goto /reviews');
    if(ret.ca.level != "ai"){
      navigate('/reviews');
    }
    else{
      setInit({done: true});
      setInputs(ret.ca);
      //setClients(jsondata.clients);
      //setDoctors(jsondata.doctors);
      //setFiles(jsondata.files);
      //setLevels(jsondata.levels);
    }
  }
  else{
    //alert('146: goto nowhere');
  }
   //setToken(token);
  
  
   
};

const handleDelete = async e => {
  //alert('226: handleSubmit');
  e.preventDefault();
  //setInputs({isloading:false, data:input})
  
  const ret = await deleteData(inputs,token.token);
  //alert(`180: handleDelete() retAddReview= ${JSON.stringify(ret)}`);
 //alert(`232: handleSubmit ret= ${JSON.stringify(ret,null,4)}`);
 if(ret && ret.level_before){
   //alert('142: goto /reviews');
   navigate('/reviews');
 }
 else{
   //alert('146: goto nowhere');
 }
  //setToken(token);
 
 
  
};

const handleRemoveFile = async (fpath) => {
    //e.preventDefault();
    //setInputs({isloading:false, data:input})
    //const rem_file = files.find(f => f.path === fpath);
    //rem_file['deleteing'] = true;
    //const orig_files = [...files];
    /*
    setFiles( files.map( f => {
      if(f.path == fpath){
        f.progress = 1;
      }
      return f;
    })
    );
    */
    
    
    //const newFiles = files.filter(f => f.path !== fpath );
    //  setFiles(newFiles)
    setIsDeleting(true);

    const {ref_num,claim_num} = inputs 
    const ret = await removeFile(fpath,ref_num,claim_num ,token.token);
    //alert(`180: handleDelete() retAddReview= ${JSON.stringify(ret)}`);
    //alert(`232: handleSubmit ret= ${JSON.stringify(ret,null,4)}`);
    
    //alert(`303: ret= ${JSON.stringify(ret,null,4)}`)
    if(ret && ret.fpath){
      //alert('142: goto /reviews');
      //navigate('/reviews');
      const newFiles = files.filter(f => f.path !== fpath );
      setFiles(newFiles);
      
    }
    setIsDeleting(false);
      
      
};

const handleGetTestData = async e => {
    
  const data = await getTestData(inputs.id,token.token);
  //alert(`150: getTestData() data= ${JSON.stringify(data)}`);
  setInputs(data);

  };

const handleClearAll = () => {

  const new_inputs = {}
  Object.entries(inputs).map(([key, value]) => {
    // Pretty straightforward - use key for the key and value for the value.
    // Just to clarify: unlike object destructuring, the parameter names don't matter here.
      new_inputs[key] = key == 'id' ? value : '';
    
})
setInputs(new_inputs)

};
  

//useEffect( () => {setInputs(init.data);},[]);

//alert(`243: inputs= ${JSON.stringify(inputs)}`);

  //const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  //const closeMenu = () => setMenu(null);

  
  //alert(`251: ${JSON.stringify(inputs,null,4)}`);




  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      {errors.length>0 &&
          <MDBox mt={8}>
            <MDBox mb={1}>
                      
              <List dense={dense}>
                {
                errors.map( item => {
                  return ( <ListItem
                    
                  >
                    {/*}
                    <ListItemAvatar>
                      <Avatar>
                        <ErrorOutlineIcon style={{ color: "red" }} fontSize="small" />
                      </Avatar>
                    </ListItemAvatar>
                  */}
                    <ListItemText style={{color:'red'}}
                      primary={item}
                      
                    />
                  </ListItem>
                  )

                })
                  
                }
              </List>
              
            </MDBox>
          </MDBox>
          
      }
      <MDBox component="form" role="form" mt={8}>
      
        <MDBox mb={6}>
          <Grid container spacing={2}>
                
                <Grid item xs={2}>
                  
                  {/*
                  <Box>
                    <TextField id="ref_num" name="ref_num" label="REF #" fullWidth variant="outlined" sx={sxtf}/>
                    <TextField id="claim_num" name="claim_num" label="CLAIM #" fullWidth variant="outlined" sx={sxtf}/>
                    <TextField id="due_date" name="due_date" label="Due date" fullWidth variant="outlined" sx={sxtf}/>
                  </Box>
                  */}

                  <Card>
                    <MDBox>
                      <MDBox mb={2} p={2} spacing={2}>
                        
                          <InputLabel id="demo-simple-select-label">Client</InputLabel>
                          <Select value={inputs.client ?? "none"} id="client" name="client" variant="standard" label="Client" labelId="demo-simple-select-label" onChange={handleChange} fullWidth>
                            
                            {clients.map((item) => {
                              return <MenuItem key={item.key} value={item.id}>{item.name}</MenuItem>
                            })}
                          </Select>
                          
                        <MDInput id="ref_num" name="ref_num"   type="text" variant="standard" label="REF #"  onChange={handleChange} value={inputs.ref_num} required fullWidth />
                        <MDInput id="claim_num" name="claim_num"   type="text" variant="standard" label="CLAIM #"  onChange={handleChange} value={inputs.claim_num} required fullWidth />
                        <MDInput id="due_date" name="due_date"   type="text" variant="standard" label="Due date"  onChange={handleChange} value={inputs.due_date} required fullWidth />
                      </MDBox>
                    </MDBox>
                  </Card>
                
                </Grid>
                <Grid item xs={2}>
                  
                  
                  
                  <Card>
                    <MDBox>
                      <MDBox mb={2} p={2}>
                        <MDInput id="patient" name="patient"   type="text"  label="Patient" variant="standard" onChange={handleChange}  value={inputs.patient} required fullWidth />
                        <MDInput id="dob" name="dob"   type="text"  label="DOB (yyyy-dd-yy)" variant="standard" onChange={handleChange}  value={inputs.dob} required fullWidth />
                        <InputLabel id="select-gender-label">Gender</InputLabel>
                        <Select value={inputs.gender ?? ""} id="gender" name="gender" variant="standard" label="Gender" labelId="select-gender-label" onChange={handleChange} required fullWidth>
                          
                          {[{id:'F',name:'Female'},{id:'M',name:'Male'}].map((item) => {
                            return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                          })}
                        </Select>
                      </MDBox>
                    </MDBox>
                  </Card>
                    
                
                </Grid>
                <Grid item xs={2}>
                  
                  
                  
                  <Card>
                    <MDBox>
                      <MDBox mb={2} p={2}>
                        <MDInput id="doi" name="doi"   type="text"  label="DOI (yyyy-dd-yy)" variant="standard" onChange={handleChange} value={inputs.doi} required fullWidth />
                        <MDInput id="dseen" name="dseen"   type="text"  label="Date seen (yyyy-dd-yy)" variant="standard" onChange={handleChange} value={inputs.dseen} required fullWidth />
                        <InputLabel id="select-label-doctor">Doctor</InputLabel>
                        <Select value={inputs.doctor ?? ""} id="doctor" name="doctor" variant="standard" label="Doctor" labelId="select-label-doctor" onChange={handleChange} required fullWidth>
                          
                          {doctors.map((item) => {
                            return <MenuItem key={item.username} value={item.username}>{item.profile.name.name}</MenuItem>
                          })}
                        </Select>
                      </MDBox>
                    </MDBox>
                  </Card>
                    
                  
                
                </Grid>
                <Grid item xs={2}>
                  
                      

                  <Card>
                    <MDBox>
                      <MDBox mb={2} p={2}>
                        <MDInput id="pa_rn" name="pa_rn" type="text" variant="outlined" label="PA/RN" variant="standard" onChange={handleChange} value={inputs.pa_rn} fullWidth />
                        <MDInput id="qa" name="qa" type="text" label="QA" variant="standard" onChange={handleChange} value={inputs.qa} fullWidth />
                        <MDInput id="insurance" name="insurance" ctype="text"  label="Insurance" variant="standard" onChange={handleChange} value={inputs.insurance} fullWidth />
                      </MDBox>
                    </MDBox>
                  </Card>
                    
                    
                  
                
                </Grid>
                
              </Grid>
          
        </MDBox>
        <MDBox mb={6}>
          <Grid container spacing={2}>
            <Grid item  xs={4}>
              <Card>
                <MDBox>
                  <MDBox mb={2} p={2}>
                  <MDInput id="notes_external" name="notes_external"  type="text"  multiline  maxRows={4} label="Notes -external" variant="standard" onChange={handleChange} value={inputs.notes_external} fullWidth />
                  </MDBox>
                </MDBox>
              </Card>
                    
            </Grid>
            <Grid item  xs={4}>
                    
              <Card>
                <MDBox>
                  <MDBox mb={2} p={2}>
                  <MDInput id="notes_internal" name="notes_internal"  type="text"  multiline  maxRows={4} label="Notes -internal" variant="standard" onChange={handleChange} value={inputs.notes_internal} fullWidth />
                  </MDBox>
                </MDBox>
              </Card>
              
            </Grid>
            
           

          </Grid>
        </MDBox>

        <MDBox mb={6}>
          <Grid container spacing={2}>
            
            <Grid item  xs={4}>
              
                    
                    
              <Card>
                <MDBox>
                  <MDBox mb={2} p={2}>
                  <MDInput id="rationale" name="rationale"  type="text"  multiline  maxRows={4} label="Rationale" variant="standard" onChange={handleChange} value={inputs.rationale} fullWidth />
                  </MDBox>
                </MDBox>
              </Card>
              
                  
            
            </Grid>
            <Grid item  xs={4}>
              
              <Card>
                <MDBox>
                  <MDBox mb={2} p={2}>
                  <MDInput id="guidelines" name="guidelines"  type="text"  multiline  maxRows={4} label="Guidelines" variant="standard" onChange={handleChange} value={inputs.guidelines} fullWidth />
                  </MDBox>
                </MDBox>
              </Card>
              
                  
            
            </Grid>
           

          </Grid>
        </MDBox>

        <MDBox mb={6}>
          <Grid container spacing={2}>
            
            <Grid item  xs={3}>
              
                    
                    
              <Card>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <MDBox>
                    <MDTypography variant="h6" gutterBottom>
                      MEDICALS
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox color="text" px={2}>
                  
                    <div>
                        {/*<label>Upload Your File </label>*/}
                        
                        <input type="file" name="attachment" className="form-control" multiple="" onChange={onUploadFileChangeHandler}/>
                        { (!isuploading) &&
                        <button type="button" class="btn btn-success btn-block" onClick={onUploadFileClickHandler}>Click to upload</button> 
                        }
                    </div>
                  
                  {isuploading &&
                    <div className="form-group">
                    <Progress max="100" color="success" value={uploadfile.loaded} >{Math.round(uploadfile.loaded,2) }%</Progress>
                    
                    </div>
                  }

                </MDBox>
                
                <MDBox p={2}>
                  
                  <List dense={dense}>
                    {
                    files.map( item => {
                      return ( <ListItem
                        secondaryAction={
                          !isdeleting &&
                          <IconButton edge="end" aria-label="delete" onClick={ ()=>handleRemoveFile(item.path)}>
                            <DeleteIcon />
                          </IconButton>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <FolderIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.filename}
                          secondary={secondary ? 'Secondary text' : null}
                        />
                      </ListItem>
                      )

                    })
                      
                    }
                  </List>
                  
                </MDBox>
                
                

              </Card>
              
                  
            
            </Grid>
            

          </Grid>
        </MDBox>


        <MDBox mb={6}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={inputs.level}
            onChange={handleChange}
            name="level"
            row
          >
            {levels.map(item => {
              return(
              <FormControlLabel key={item.id} value={item.id} control={<Radio />} checked={inputs.level === item.id} label={item.name} />  
              )
            })}
            {/*}
            <FormControlLabel value="client" control={<Radio />} checked={inputs.level === 'client'} label="CLIENT" />
            <FormControlLabel value="ml" control={<Radio />} checked={inputs.level === 'ai'} label="AI" />
            <FormControlLabel value="fl" control={<Radio />} checked={inputs.level === 'fl'} label="FL" />
            <FormControlLabel value="sl" control={<Radio />} checked={inputs.level === 'sl'} label="SL" />
            <FormControlLabel value="qa" control={<Radio />} checked={inputs.level === 'qa'} label="QA" />
            <FormControlLabel value="rl" control={<Radio />} checked={inputs.level === 'rl'} label="RL" />
                  */}
          </RadioGroup>
        </MDBox>
        <MDBox mb={1}>
          <Grid container spacing={0.5}>
              
              <Grid item>
                  <MDButton
                    color="dark"
                    variant="gradient"
                    onClick={handleUpdate}
                  >
                    Submit
                  </MDButton>
              </Grid>
              <Grid item>
                  <MDButton
                    color="dark"
                    variant="gradient"
                    onClick={handleDelete}
                  >
                    Delete
                  </MDButton>
              </Grid>
              <Grid item>
                  <MDButton
                    color="dark"
                    variant="gradient"
                    onClick={handleGetTestData}
                    
                  >
                    Sample Data
                  </MDButton>
                  </Grid>
              
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditCase;
