
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
//import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

//ROM:
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import useToken from 'useToken';
import appconfig from "appconfig";
import Reviews from "layouts/dashboard/components/Reviews";
import MyMessages from "layouts/dashboard/components/MyMessages";

function Dashboard() {

  //const navigate = useNavigate();
  
  const { token, setToken } = useToken();

  //alert(`38: token= ${JSON.stringify(token)}`);
  if(!token){

    //return navigate("/authentication/sign-in");
    
    return (<Navigate to="/authentication/sign-in" />);
  }
  
  const { sales, tasks } = reportsLineChartData;
  const [report, setReport] = useState({isloading:true,data:{}});


  useEffect(() => {
    
    //const post_data = {session:{sessionId:token}}

    fetch(`${appconfig.api_root}/cases/dashboard`,
        {
          method: 'POST',
          headers: {'Content-Type':'application/json'},
          body: JSON.stringify( {session:{sessionId:token.token}})
        }    
    )
    .then((res) => res.json())
    .then((jsondata) => {
      //alert(`61: ${JSON.stringify(jsondata)}`);
      setReport( {isloading:false, data:jsondata})
  
  });
  },[]);


  if(report.isloading){
    return (
     <DashboardLayout>
       <DashboardNavbar />
       <MDBox pt={6} pb={3}>
         <p>Loading...</p>
       </MDBox>
       
       <Footer />
     </DashboardLayout>
    );
  }
  
  //alert(`81: totals: ${JSON.stringify(totals,null,4)}`);

  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="leaderboard"
                title="PREP"
                count={report.data.totals.client.count}
                percentage={{
                  color: "error",
                  amount: report.data.totals.client.duetoday,
                  label: "due",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="AI"
                count={report.data.totals.ai.count}
                percentage={{
                  color: "error",
                  amount: report.data.totals.ai.duetoday,
                  label: "due",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="FL"
                count={report.data.totals.fl.count}
                percentage={{
                  color: "error",
                  amount: report.data.totals.fl.duetoday,
                  label: "due",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="leaderboard"
                title="SL"
                count={report.data.totals.sl.count}
                percentage={{
                  color: "error",
                  amount: report.data.totals.sl.duetoday,
                  label: "due",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="leaderboard"
                title="QA"
                count={report.data.totals.qa.count}
                percentage={{
                  color: "error",
                  amount: report.data.totals.qa.duetoday,
                  label: "due",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="weekly reviews"
                  
                  description=""
                  date=""
                  chart={report.data.weekly}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="monthly reviews"
                  
                  description={
                    <>
                      {/*(<strong>+15%</strong>) increase in today reviews.*/}
                    </>
                  }
                  date="updated 4 min ago"
                  chart={report.data.monthly}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="annual reviews"
                  description=""
                  date="just updated"
                  chart={report.data.yearly}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Reviews cases={report.data.cases} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {/*<OrdersOverview /> */}
              <MyMessages messages={report.data.messages} token={token} />

            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
