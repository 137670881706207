

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";

// Images
import LogoAsana from "assets/images/small-logos/logo-asana.svg";
import logoGithub from "assets/images/small-logos/github.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoInvesion from "assets/images/small-logos/logo-invision.svg";





export default function data(cases) {
  //alert('LINE 26:');
  //ROM
  //const [cases,setCases] = useState([]); 
  
  const Patient = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" variant="rounded" />
      <MDTypography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  const Progress = ({ color, value }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {value}%
      </MDTypography>
      <MDBox ml={0.5} width="9rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  );

  

  //alert(`99: cases= ${JSON.stringify(cases,null,4)}`);

  const rows = cases.map((item) => {
    return {
      patient: <Patient name={item.patient} />,
      refnum: (
        <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
          {item.ref_num}
        </MDTypography>
      ),
      status: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item.level}
        </MDTypography>
      ),
      completion: <Progress color="info" value={60} />,
      action: (
        <MDTypography component="a" href="#" color="text">
          <Icon>edit</Icon>
        </MDTypography>
      ),
    }

  });

  return {
    columns: [
      { Header: "patient", accessor: "patient", width: "30%", align: "left" },
      { Header: "ref#", accessor: "refnum", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "completion", accessor: "completion", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

/*    
    rows: cases.cases.map((r) => {
        return {
          project: <Patient name={r.patient} />,
          budget: (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              {r.ref_num}
            </MDTypography>
          ),
          status: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {r.level}
            </MDTypography>
          ),
          completion: <Progress color="info" value={60} />,
          action: (
            <MDTypography component="a" href="#" color="text">
              <Icon>more_vert</Icon>
            </MDTypography>
          ),
        }
    })  
    */
    
   rows: rows
    /*
    rows: [
      {
        project: <Project name="Ryan Hadid" />,
        budget: (
          <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
            R101
          </MDTypography>
        ),
        status: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            working
          </MDTypography>
        ),
        completion: <Progress color="info" value={60} />,
        action: (
          <MDTypography component="a" href="#" color="text">
            <Icon>more_vert</Icon>
          </MDTypography>
        ),
      },
      
    ],
    */
    
  };
}
