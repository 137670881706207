
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import appconfig from "appconfig";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";


// Material Dashboard 2 React example components
//import TimelineItem from "examples/Timeline/TimelineItem";
import { TimelineItem } from '@mui/lab';


//ROM:
import { useState  } from "react";
import { Accordion, AccordionSummary, AccordionDetails }  from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import Timeline from '@mui/lab/Timeline';

import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Tooltip from "@mui/material/Tooltip";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";

//Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import sam from "assets/images/sam.jpg";
import noimage from "assets/images/no-image.jpg";


async function submitData(inputs,token){
  //alert(`token= ${token}`);
  //alert(`inputs= ${JSON.stringify(inputs,null,4)}`);
  const post_data = {session:{sessionId: token}, data:inputs};
  return fetch(`${appconfig.api_root}/mymessages/add`,
    {
      method: 'POST',
      headers:{'Content-Type':'application/json'},
      body: JSON.stringify(post_data)
    }
  )
  .then(res => {
    //alert(`res= ${JSON.stringify(res)}`);
    return res.json();

  })
  .then(jsondata => {
    return jsondata;
  });

}

function MyMessages(props) {

  if(!props.token){
    //alert('80: AddCase() !token');
    return (<Navigate to="/authentication/sign-in" />);
  }
  
  //alert(`props= ${JSON.stringify(props)}`)
  const [inputs, setInputs] = useState({to:'',message:''})
  const [expand, setExpand] = useState(false);
  const [messages, setMessages] = useState(props.messages)
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };


  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]:value}));

  }

  const handleSubmit = async e => {
    
    e.preventDefault();
    
    
    const m = {...inputs};
    //alert(`m= ${JSON.stringify(m)}`);
    m.to = m.to.trim();
    m.message = m.message.trim();
    if(m.to && m.message){
      
      const ret = await submitData(m,props.token.token);
      //alert(`82: ret= ${JSON.stringify(ret,null,4)}`);
      if(ret){
        
        
      
        setInputs({to:m.to,message:''});
        toggleAcordion();

        setMessages(ret.messages);

        //alert(`87: messages= ${JSON.stringify(messages,null,4)}`);
      }
      
    }
    else{
      //alert('75: ');
    }
    
  }

  //alert(`props.messages= ${JSON.stringify(prop.messages,null,4)}`);
  let rows = 'No messages';
  if(messages.length > 0){
    
    rows = messages.map((m) => {

      //messages.map((m) => {
            return(
        <TimelineItem key={m.id}>
          {/*}
          <TimelineOppositeContent
            >
            
            <MDTypography variant="body" color="secondary">
            {m.created_ago}
            </MDTypography>
          </TimelineOppositeContent>
            */}
          <TimelineSeparator>
              <TimelineDot>
                <Tooltip key={m.id} title={m.from.name} placeholder="bottom">
                  <MDAvatar
                    src={`/images/${m.from.username}.jpg`}
                    alt={m.from.name}
                    size="xs"
                    sx={{
                      border: ({ borders: { borderWidth }, palette: { white } }) =>
                        `${borderWidth[2]} solid ${white.main}`,
                      cursor: "pointer",
                      position: "relative",

                      "&:not(:first-of-type)": {
                        ml: -1.25,
                      },

                      "&:hover, &:focus": {
                        zIndex: "10",
                      },
                    }}
                  />
                </Tooltip>
              </TimelineDot>
              <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
          

            <MDTypography variant="caption">
            {`@${m.from.name} ${m.created_ago}`}
            </MDTypography>
            
            <MDTypography variant="body" color="secondary"  display="block">{m.message}</MDTypography>
            {/*<MDTypography variant="caption" color="secondary"  display="block">{`${m.to.name} @${m.to.username}`}</MDTypography> */}
          
         </TimelineContent>
      </TimelineItem> 
            )
      
      
    //})
      if(m.is_mine){
      return (
        <TimelineItem
        key={m.id}
        position="left"
          icon={<PersonIcon />}
          title={ m.message}
          dateTime={`@${m.to.name} ${m.created_ago}`}
        />
        
        
      );
      }
      else{
        return (
          <TimelineItem
          key={m.id}
          position="right"
            icon={<PersonIcon />}
            title={ m.message}
            dateTime={`@${m.to.name} ${m.created_ago}`}
          />
          
          
        );
      }
      if(m.is_mine){
        
        return (
        <TimelineItem
          //color= {m.is_mine? "success": "info"}
          icon={<PersonIcon />}
          //title={ m.message}
          //dateTime={`@${m.to.name} ${m.created_ago}`}
        >
          <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          //color="text.secondary"
        >
          {m.created_ago}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <MDTypography variant="h6" component="span">
            Me
          </MDTypography>
          <MDTypography>{m.message}</MDTypography>
        </TimelineContent>
        </TimelineItem>
        )

      }
      return (
        <TimelineItem
          //color= {m.is_mine? "success": "info"}
          icon={<PersonIcon />}
          //title={ m.message}
          //dateTime={`@${m.to.name} ${m.created_ago}`}
        >
          <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          //color="text.secondary"
        >
          {m.created_ago}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <MDTypography variant="h6" component="span">
            {m.from.name}
          </MDTypography>
          <MDTypography>{m.message}</MDTypography>
        </TimelineContent>
        </TimelineItem>
        )
    })
  }

  return (
    
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
      <Accordion expanded={expand}>
        <AccordionSummary
          expandIcon={<SendIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={toggleAcordion}
        >
          
        </AccordionSummary>
        <AccordionDetails>
        <MDBox>
          <MDBox mb={2} p={2}>
          <MDInput name="to"  type="text" label="To" variant="standard" onChange={handleChange} value={inputs.to} fullWidth />
          </MDBox>
          <MDBox mb={2} p={2}>
          <MDInput name="message"  type="text"  multiline  maxRows={4} label="Your message here" variant="standard" onChange={handleChange} value={inputs.message} fullWidth />
          </MDBox>
          <MDButton
                    color="dark"
                    variant="gradient"
                    onClick={handleSubmit}
                    
                  >
                    Send
                  </MDButton>
        </MDBox>
          </AccordionDetails>
          </Accordion>
        
        <MDTypography variant="h6" fontWeight="medium">
          Messages
        </MDTypography>
        <MDBox p={2}>
        <Timeline>
          {rows}
          {/*}
                <TimelineItem position="left">
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        Frontend Learning Path
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>HTML</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>CSS</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                    </TimelineSeparator>
                    <TimelineContent>Javascript</TimelineContent>
                </TimelineItem>
        */}
            </Timeline>
            
        </MDBox>
      </MDBox>
    </Card>

    
  );
}

export default MyMessages;
