import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    //alert(`7: userToken= ${JSON.stringify(userToken)}`);
    //return userToken?.token
    return userToken
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
      //alert(`13 saveToken( userToken= ${JSON.stringify(userToken,null,4)})`);

    sessionStorage.setItem('token', JSON.stringify(userToken));
    //setToken(userToken.token);
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token
  }
}