



// react-router-dom components
import { Link, useNavigate, Navigate } from "react-router-dom";



export default function SignOut() {
  
  sessionStorage.clear();
  return (<Navigate to="/authenticate/sign-in"/>);
  
}

//export default Login;
/*
Login.propTypes = {
  setToken: PropTypes.func.isRequired
}
*/