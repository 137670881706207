


// react-router-dom components


// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import appconfig from "appconfig";
// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

import { useState, useEffect} from "react"

import { Link, useNavigate, Navigate } from "react-router-dom";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import useToken from 'useToken';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem'

async function submitSignup(inputs){
  return fetch(`${appconfig.api_root}/accounts/clientsignup`,
    {
      method:"POST",
      headers: {"Content-Type":"application/json"},
      body: JSON.stringify(inputs)
    })
    .then(res => res.json())
    
}

async function loginUser(credentials) {
  //alert('35: loginUser');
  return fetch(`${appconfig.api_root}/accounts/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
 }


function Cover(props) {
//alert(`55: props= ${JSON.stringify(props)}`);
const defaulterrormsg = 'There was an error processing your request.';

/*
const test_signup = {
   username:'test2',password:'test2',email:'test',name:'Test 2',specialty:'101', title:'Dr.',business:'test2 business', phone:'3105555555', address:'150 Las Vegas Blvd, Nevada, 89101 USA'};
*/
const navigate = useNavigate();
   const {token, setToken} = useToken();
  const [inputs, setInputs] = useState({ username:'',password:'',email:'',name:'',specialty:'', title:'', business:'',phone:'',address:''});


  const [errormessage,setErrorMessage] = useState();
  const [specialties, setSpecialties] = useState();

  useEffect(() =>{
    fetch(`${appconfig.api_root}/accounts/startclientsignup`,{
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body:JSON.stringify({istest:props.istest})
    })
    .then(res => res.json())
    .then(jsondata => {
      //alert(`72: ${JSON.stringify(jsondata)}`);
      
      const {test,specialties} = jsondata;  
      setSpecialties(specialties);
      if(test && test.username){
        setInputs(test);
      }

        
    })
  },[]);

  if(!specialties){
    return (
      <CoverLayout image={bgImage}/>
    )
  }

  
  const handleSubmit = async e => {
    e.preventDefault();
    setErrorMessage('');
    const ret_signup = await submitSignup(inputs);
    //alert(`100: ret_signup= ${JSON.stringify(ret_signup,null,4)}`)
    if(ret_signup.username && ret_signup.password){
      //alert(`102: ret_signup= ${JSON.stringify(ret_signup,null,4)}`)
      
      const {username,password} = ret_signup;
      const ret_login = await loginUser({
        username,
        password
      });
      //alert(`67: handleSubmit() tokenn= ${JSON.stringify(ret_login)}`);
  

      if(ret_login && ret_login.token){
        setToken(ret_login);
        navigate("/dashboard");
      }
      else{
        setErrorMessage(`90: ${defaulterrormsg}`);
      }

    }
    else{
      setErrorMessage(`96: ${defaulterrormsg}`);
    }


  }
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInputs({...inputs,[name]:value});
    
  }



  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            AI Powered Physician Review Services
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Join us
          </MDTypography>
      
        
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            
            <MDBox mb={2}>
              <MDInput type="text" label="Username" variant="standard" value = {inputs.username} onChange={handleChange} fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" variant="standard" value = {inputs.password} onChange={handleChange} fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="email" label="Email" variant="standard" value = {inputs.email} onChange={handleChange} fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="text" label="Name" variant="standard" value = {inputs.name}  onChange={handleChange} fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="text" label="Address" variant="standard" value = {inputs.address}  onChange={handleChange} fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="text" label="Phone#" variant="standard" value = {inputs.phone}  onChange={handleChange} fullWidth />
            </MDBox>
            <MDBox mb={2}>
              {/* <MDInput type="text" label="Specialty" variant="standard" value = {inputs.specialty}  onChange={handleChange} fullWidth /> */}
              <InputLabel id="specialty-select-label">Specialty</InputLabel>
              <Select value={inputs.specialty ?? "none"} id="specialty" name="specialty" variant="standard" label="Specialty" labelId="specialty-select-label" onChange={handleChange} fullWidth>
                
                {specialties.map((item) => {
                  return <MenuItem key={item.key} value={item.id}>{item.name}</MenuItem>
                })}
              </Select>
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="text" label="Affiliation/Business name" variant="standard" value = {inputs.business}  onChange={handleChange} fullWidth />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" onClick={handleSubmit} fullWidth>
                sign up
              </MDButton>

            </MDBox>
            <MDBox>
              <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  fontWeight="bold"
                  color="info"
                  textGradient
                >
                  {errormessage}
                </MDTypography>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
