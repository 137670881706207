
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

//ROM
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";



// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import MasterCard from "examples/Cards/MasterCard";
//import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
//import PaymentMethod from "layouts/billing/components/PaymentMethod";
//import Invoices from "layouts/billing/components/Invoices";
//import BillingInformation from "layouts/billing/components/BillingInformation";
//import Transactions from "layouts/billing/components/Transactions";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import appconfig from 'appconfig';
import useToken from 'useToken';




async function getTestData() {
  //alert(`35: getTestData() ${appconfig.api_root}`);
  return fetch(`${appconfig.api_root}/cases/gettestdata`
  /*, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
    
  }*/
  )
    .then(data => {
      
      return data.json();
    })
 }

 async function submitData(input_data, token) {
  //alert(`55: submitData(input_data= ${JSON.stringify(input_data)} token= ${JSON.stringify(token)})`);
  const post_data = { session:{sessionId: token}, data:input_data}
  //alert(`57: post_data= ${JSON.stringify(post_data,null,4)}`)
  return fetch(`${appconfig.api_root}/cases/postadd`
  , {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(post_data)
    
  }
  )
    .then(data => {
      
      return data.json();
    })
 }


function AddCase() {
  const default_data = {
    //isloading:true,
    //data:{
      //status: 0,
      client: 'none',
      ref_num:'',
      claim_num: '',
      due_date: '',
      patient:'',
      dob:'',
      gender:'F',
      doi:'',
      dseen:'',
      doctor:'',
      pa_rn:'',
      qa:'',
      insurance:'',
      notes_external:'',
      notes_internal:'',
      rationale:'',
      guidelines:'',
      level:'CLIENT',
  
      //}
  };
  const navigate = useNavigate();
  const {token,setToken} = useToken();
  //alert(`77: AddCase() token= ${JSON.stringify(token)}`);

  if(!token){
    //alert('80: AddCase() !token');
    return (<Navigate to="/authentication/sign-in" />);
  }
  /*const sxtf = {
    input: {
      
      background: "white"
    }
  };
*/

const [init,setInit] = useState({done:false});

const [clients,setClients] = useState([])


const [inputs,setInputs] = useState(default_data);
const [doctors,setDoctors] = useState([]);

useEffect(()=>{
  const post_data = {session:{sessionId: token}};
  
  fetch(`${appconfig.api_root}/cases/getadd`,
  {
    method:'POST',
    headers:{'Content-Type':'application/json'},
    body: JSON.stringify(post_data)

  })
  .then(res => res.json())
  .then(jsondata => {
    setInit({done: true});
    setInputs(jsondata.ca);
    setClients(jsondata.clients);
    setDoctors(jsondata.doctors);
  });

},[])

if(!init.done){
  return (
   <DashboardLayout>
     <DashboardNavbar />
     <MDBox pt={6} pb={3}>
       <p>Loading...</p>
     </MDBox>
     
     <Footer />
   </DashboardLayout>
  );
}
  
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
  }


  const handleSubmit = async e => {
     //alert('handleSubmit');
     e.preventDefault();
     const ret = await submitData(inputs,token);
     //alert(`138: handleSubmit() retAddReview= ${JSON.stringify(ret)}`);
    if(ret && ret.id){
      //alert('142: goto /reviews');
      navigate('/reviews');
    }
    else{
      alert('146: goto nowhere');
    }
     //setToken(token);
    
    
     
  };

  const handleClearAll = () => setInputs(default_data);
  

  const handleGetTestData = async e => {
    
  const data = await getTestData();
  //alert(`150: getTestData() data= ${JSON.stringify(data)}`);
  setInputs(data);

  };

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox component="form" role="form" mt={8}>
        <MDBox mb={6}>
          <Grid container spacing={2}>
                
                <Grid item xs={2}>
                  
                  

                  <Card>
                    <MDBox>
                      <MDBox mb={2} p={2}>
                        <InputLabel id="select-label-client">Client</InputLabel>
                        <Select value={inputs.client ?? "none"} id="client" name="client" variant="standard" label="Client" labelId="select-label-client" onChange={handleChange} fullWidth>
                          
                          {clients.map((item) => {
                            return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                          })}
                        </Select>
                        <MDInput id="ref_num" name="ref_num"   type="text" variant="outlined" label="REF #" variant="standard" onChange={handleChange} value={inputs.ref_num}  />
                        <MDInput id="claim_num" name="claim_num"   type="text" variant="outlined" label="CLAIM #" variant="standard" onChange={handleChange} value={inputs.claim_num}  />
                        <MDInput id="due_date" name="due_date"   type="text" variant="outlined" label="Due date" variant="standard" onChange={handleChange} value={inputs.due_date}  />
                      </MDBox>
                    </MDBox>
                  </Card>
                
                </Grid>
                <Grid item xs={2}>
                  
                  
                  
                  <Card>
                    <MDBox>
                      <MDBox mb={2} p={2}>
                        <MDInput id="patient" name="patient"   type="text"  label="Patient" variant="standard" onChange={handleChange} value={inputs.patient} fullWidth />
                        <MDInput id="dob" name="dob"   type="text"  label="DOB (yyyy-dd-yy)" variant="standard" onChange={handleChange} value={inputs.dob} fullWidth />
                        {/*<MDInput id="gender" name="gender"   type="text"  label="Gender (M,F)" variant="standard" onChange={handleChange} value={inputs.gender} fullWidth /> */}
                        <InputLabel id="select-gender-label">Gender</InputLabel>
                        <Select value={inputs.gender ?? ""} id="gender" name="gender" variant="standard" label="Gender" labelId="select-gender-label" onChange={handleChange} fullWidth>
                          
                          {[{id:'F',name:'Female'},{id:'M',name:'Male'}].map((item) => {
                            return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                          })}
                        </Select>
                      </MDBox>
                    </MDBox>
                  </Card>
                    
                
                </Grid>
                <Grid item xs={2}>
                  
                  
                  
                  <Card>
                    <MDBox>
                      <MDBox mb={2} p={2}>
                        <MDInput id="doi" name="doi"   type="text"  label="DOI (yyyy-dd-yy)" variant="standard" onChange={handleChange} value={inputs.doi} fullWidth />
                        <MDInput id="dseen" name="dseen"   type="text"  label="Date seen (yyyy-dd-yy)" variant="standard" onChange={handleChange} value={inputs.dseen} fullWidth />
                        {/*<MDInput id="doctor" name="doctor"   type="text"  label="Doctor" variant="standard" onChange={handleChange} value={inputs.doctor} fullWidth /> */}
                        
                        <InputLabel id="select-label-doctor">Doctor</InputLabel>
                        <Select value={inputs.doctor ?? ""} id="doctor" name="doctor" variant="standard" label="Doctor" labelId="select-label-doctor" onChange={handleChange} fullWidth>
                          
                          {doctors.map((item) => {
                            return <MenuItem key={item.username} value={item.username}>{item.profile.name.name}</MenuItem>
                          })}
                        </Select>

                      </MDBox>
                    </MDBox>
                  </Card>
                    
                  
                
                </Grid>
                <Grid item xs={2}>
                  
                      

                  <Card>
                    <MDBox>
                      <MDBox mb={2} p={2}>
                        <MDInput id="pa_rn" name="pa_rn" type="text" variant="outlined" label="DOI (yyyy-dd-yy)" variant="standard" onChange={handleChange} value={inputs.pa_rn} fullWidth />
                        <MDInput id="qa" name="qa" type="text" label="Date seen (yyyy-dd-yy)" variant="standard" onChange={handleChange} value={inputs.qa} fullWidth />
                        <MDInput id="insurance" name="insurance" ctype="text"  label="Insurance" variant="standard" onChange={handleChange} value={inputs.insurance} fullWidth />
                      </MDBox>
                    </MDBox>
                  </Card>
                    
                    
                  
                
                </Grid>
                
              </Grid>
          
        </MDBox>
        <MDBox mb={6}>
          <Grid container spacing={2}>
            <Grid item  xs={4}>
              <Card>
                <MDBox>
                  <MDBox mb={2} p={2}>
                  <MDInput id="notes_external" name="notes_external"  type="text"  multiline  maxRows={4} label="Notes -external" variant="standard" onChange={handleChange} value={inputs.notes_external} fullWidth />
                  </MDBox>
                </MDBox>
              </Card>
                    
            </Grid>
            <Grid item  xs={4}>
                    
              <Card>
                <MDBox>
                  <MDBox mb={2} p={2}>
                  <MDInput id="notes_internal" name="notes_internal"  type="text"  multiline  maxRows={4} label="Notes -internal" variant="standard" onChange={handleChange} value={inputs.notes_internal} fullWidth />
                  </MDBox>
                </MDBox>
              </Card>
              
            </Grid>
            
           

          </Grid>
        </MDBox>

        <MDBox mb={6}>
          <Grid container spacing={2}>
            
            <Grid item  xs={4}>
              
                    
                    
              <Card>
                <MDBox>
                  <MDBox mb={2} p={2}>
                  <MDInput id="rationale" name="rationale"  type="text"  multiline  maxRows={4} label="Rationale" variant="standard" onChange={handleChange} value={inputs.rationale} fullWidth />
                  </MDBox>
                </MDBox>
              </Card>
              
                  
            
            </Grid>
            <Grid item  xs={4}>
              
              <Card>
                <MDBox>
                  <MDBox mb={2} p={2}>
                  <MDInput id="guidelines" name="guidelines"  type="text"  multiline  maxRows={4} label="Guidelines" variant="standard" onChange={handleChange} value={inputs.guidelines} fullWidth />
                  </MDBox>
                </MDBox>
              </Card>
              
                  
            
            </Grid>
           

          </Grid>
        </MDBox>
        
        <MDBox mb={1}>
          <Grid container spacing={0.5}>
              
              <Grid item>
                  <MDButton
                    color="dark"
                    variant="gradient"
                    onClick={handleSubmit}
                  >
                    Submit
                  </MDButton>
              </Grid>
              <Grid item>
                  <MDButton
                    color="dark"
                    variant="gradient"
                    onClick={handleClearAll}
                    
                  >
                    Clear
                  </MDButton>
                  </Grid>
              <Grid item>
                  <MDButton
                    color="dark"
                    variant="gradient"
                    onClick={handleGetTestData}
                    
                  >
                    Test Data
                  </MDButton>
                  </Grid>
          </Grid>
        </MDBox>
        
        
  
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddCase;
