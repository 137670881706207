
// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";

// Images
import logoXD from "assets/images/small-logos/logo-xd.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoJira from "assets/images/small-logos/logo-jira.svg";
import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

export default function data(cases) {
  //alert(`22: cases= ${JSON.stringify(cases,null,4)}`);
  /*
  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={image}
          alt="name"
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));
    */
    const avatars = (members) =>
    members.map((m) => {
      
      //alert(`84: item= ${JSON.stringify(m,null,4)}`)
      return (
      
      <Tooltip key={m.username} title={m.profile.name.name} placeholder="bottom">
        <MDAvatar
        
          src={`/images/${m.username}.jpg`}
          alt={m.profile.name.name}
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    )
  });

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  const rows = cases.map((item) => {
    
    let progress = 10;
    switch(item.level.toLowerCase()){
      case 'client':
        progress = 0;
        break;
      case 'ai':
        progress = 60;
        break;
      case 'fl':
        progress = 70;
        break;

      case 'sl':
        progress = 80;
        break;
      
      case 'qa':
        progress = 90;
        break;
      
      case 'rl':
        progress = 100;
        break;

    }

    
    return {
      companies: <Company  name={item.patient} />,
      /*members: (
        
        <MDBox display="flex" py={1}>
          {avatars([
            [team1, "Ryan Tompson"],
            [team2, "Romina Hadid"],
            [team3, "Alexander Smith"],
            [team4, "Jessica Doe"],
          ])}
        </MDBox>

      ),
      */
      members: (
        
        <MDBox display="flex" py={1}>
          {avatars(item.reviewers)}
        </MDBox>

      ),
      budget: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {item.ref_num}
        </MDTypography>
      ),
      level: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {item.level}
        </MDTypography>
      ),
      completion: (
        <MDBox width="8rem" textAlign="left">
          <MDProgress value={progress} color={item.is_duetoday ? "error": "success"} variant="gradient" label={false} />
        </MDBox>
      ),
    };

  });

  return {
    columns: [
      { Header: "patients", accessor: "companies", width: "45%", align: "left" },
      { Header: "reviewers", accessor: "members", width: "10%", align: "left" },
      { Header: "ref#", accessor: "budget", align: "center" },
      { Header: "level", accessor: "level", width: "45%", align: "left" },
      { Header: "completion", accessor: "completion", align: "center" },
    ],

    rows: rows,
  };
}
