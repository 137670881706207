
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";



// Data
//import authorsTableData from "layouts/tables/data/authorsTableData";
//import projectsTableData from "layouts/tables/data/projectsTableData";
import reviewsTableData from "layouts/reviews/data/reviewsTableData";

//ROM
import { useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import appconfig from "appconfig";

import TabsRender from "examples/Rom/TabsRender";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

/*
type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
}
*/


function Reviews() {
  //const { columns, rows } = authorsTableData();
  //const { columns: pColumns, rows: pRows } = projectsTableData();
  const [tabledata, setData] = useState({isloading:true,data:{}});
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const navigate = useNavigate();

  
  useEffect(() => {
    
    let q = 'all';
    switch(tabValue){
      case 0:
        q = 'all';
        break;
      case 1:
        q = 'prep';
        break;
      case 2:
        q = 'ml';
        break;
      case 3:
        q = 'fl';
        break;
      case 4:
        q = 'sl';
        break;
      case 5:
        q = 'qa';
        break;
      case 6:
        q = 'rl';
        break;
    }
    
    const url = `${appconfig.api_root}/cases/${q}` 
    //alert(`83: useEffect url=${JSON.stringify(url)}`);
    
    fetch(url)
      .then((res) => {
        //alert(`32: res= ${JSON.stringify(res,null,4)}`);
        return res.json();
      })
      .then((jsondata) => setData( {isloading:false, data:jsondata}));
 }, [tabValue]);


 useEffect(() => {
  // A function that sets the orientation state of the tabs.
  function handleTabsOrientation() {
    return window.innerWidth < breakpoints.values.sm
      ? setTabsOrientation("vertical")
      : setTabsOrientation("horizontal");
  }

  /** 
   The event listener that's calling the handleTabsOrientation function when resizing the window.
  */
  window.addEventListener("resize", handleTabsOrientation);

  // Call the handleTabsOrientation function to set the state with the initial value.
  handleTabsOrientation();

  // Remove event listener on cleanup
  return () => window.removeEventListener("resize", handleTabsOrientation);
}, [tabsOrientation]);

const handleSetTabValue = (event, newValue) => {
  //alert(`87: newValue= ${newValue}`); 
  setData({isloading:true,data:{}});
  setTabValue(newValue);
};

 
 //alert(`+++35: tabledata= ${JSON.stringify(tabledata,null,4)}`);
 if(tabledata.isloading){
   return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <p>Loading...</p>
      </MDBox>
      
      <Footer />
    </DashboardLayout>
   );
 }


  



 
  const { columns: pColumns, rows: pRows } =  reviewsTableData(tabledata.data.cases, navigate);
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        
        <Grid container spacing={6}>
          
          
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex" justifyContent="space-between" alignItems="center"
              >
                {/*
                <MDTypography variant="h6" color="white">
                  Reviews
                </MDTypography>
                */}

                {/*
                <MDButton variant="gradient" color="dark" onClick={() => navigate("/reviews/all")}>
                  
                  &nbsp;ALL
                </MDButton>
                <MDButton variant="gradient" color="dark" onClick={() => navigate("/reviews/fl")}>
                  &nbsp;FL
                </MDButton>
                <MDButton variant="gradient" color="dark" onClick={() => navigate("/reviews/sl")}>
                  &nbsp;SL
                </MDButton>
                <MDButton variant="gradient" color="dark" onClick={() => navigate("/reviews/qa")}>
                  &nbsp;QA
                </MDButton>
                <MDButton variant="gradient" color="dark" onClick={() => navigate("/reviews/rl")}>
                  &nbsp;RL
                </MDButton>
                */}
                <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  
                  label={
                    <div>
                      <div>
                      <MDTypography variant="title">
                        ALL
                      </MDTypography>
                      </div>
                      <div>
                      <MDTypography variant="title" sx={{ color: "grey" }} >
                        {tabledata.data.totals.all}
                        
                      </MDTypography>
                      </div>
                      
                      
                    </div>
                 }
                  
                  
                />
                <Tab
                  label={
                    <div>
                      <div>
                      <MDTypography variant="title">
                        PREP
                      </MDTypography>
                      </div>
                      <div>
                      <MDTypography variant="title" sx={{ color: "grey" }}>
                        {tabledata.data.totals.client}
                        
                      </MDTypography>
                      </div>
                      
                      
                    </div>
                 }
                  
                />
                <Tab
                  label={
                    <div>
                      <div>
                      <MDTypography variant="title">
                        AI
                      </MDTypography>
                      </div>
                      <div>
                      <MDTypography variant="title" sx={{ color: "grey" }}>
                        {tabledata.data.totals.ai}
                        
                      </MDTypography>
                      </div>
                      
                      
                    </div>
                 }
                  
                />
                <Tab
                  label={
                    <div>
                      <div>
                      <MDTypography variant="title">
                        FL
                      </MDTypography>
                      </div>
                      <div>
                      <MDTypography variant="title" sx={{ color: "grey" }}>
                        {tabledata.data.totals.fl}
                        
                      </MDTypography>
                      </div>
                      
                      
                    </div>
                 }
                  
                />
                <Tab
                  label={
                    <div>
                      <div>
                      <MDTypography variant="title">
                        SL
                      </MDTypography>
                      </div>
                      <div>
                      <MDTypography variant="title" sx={{ color: "grey" }}>
                        {tabledata.data.totals.sl}
                        
                      </MDTypography>
                      </div>
                      
                      
                    </div>
                 }
                  
                />
                <Tab
                  label={
                    <div>
                      <div>
                      <MDTypography variant="title">
                        QA
                      </MDTypography>
                      </div>
                      <div>
                      <MDTypography variant="title" sx={{ color: "grey" }}>
                        {tabledata.data.totals.qa}
                        
                      </MDTypography>
                      </div>
                      
                      
                    </div>
                 }
                  
                />
                <Tab
                  label={
                    <div>
                      <div>
                      <MDTypography variant="title">
                        RL
                      </MDTypography>
                      </div>
                      <div>
                      <MDTypography variant="title" sx={{ color: "grey" }}>
                        {tabledata.data.totals.rl}
                        
                      </MDTypography>
                      </div>
                      
                      
                    </div>
                 }
                  
                />
                
              </Tabs>
                
                <MDButton variant="gradient" color="dark" onClick={() => navigate("/reviews/edit",{state:{id:"0"}})}>
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp;add new case
                </MDButton>
                
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/*
      <MDBox sx={{ mx: 1, width: "8rem", minWidth: "8rem" }}>
        <MDButton
          color="dark"
          variant="gradient"
          onClick={handleTransparentSidenav}
          disabled={disabled}
          fullWidth
          sx={
            transparentSidenav && !whiteSidenav
              ? sidenavTypeActiveButtonStyles
              : sidenavTypeButtonsStyles
          }
        >
          Submit
        </MDButton>
      </MDBox>
      */}
      
      <Footer />
    </DashboardLayout>
  );
}

export default Reviews;
