import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// @mui material components
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

//ROM

import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';




// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";

//ROM:
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import { useState, useEffect} from 'react'
import appconfig from "appconfig";
import useToken from 'useToken';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

async function deleteData(input_data, token) {
  //alert(`55: submitData(input_data= ${JSON.stringify(input_data)} token= ${JSON.stringify(token)})`);
  const post_data = { session:{sessionId: token}, data:{id:input_data.id}}
  //alert(`57: post_data= ${JSON.stringify(post_data,null,4)}`)
  return fetch(`${appconfig.api_root}/clients/postremove`
  , {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(post_data)
    
  }
  )
    .then(data => {
      
      return data.json();
    })
 }

 async function updateData(input_data, token) {
  //alert(`55: submitData(input_data= ${JSON.stringify(input_data)} token= ${JSON.stringify(token)})`);
  const post_data = { session:{sessionId: token}, data:input_data}
  //alert(`57: post_data= ${JSON.stringify(post_data,null,4)}`)
  return fetch(`${appconfig.api_root}/clients/postedit`
  , {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(post_data)
    
  }
  )
    .then((res) => {
      
      return res.json();
    })
    .then((jsondata) => {
      //alert(`99: ${JSON.stringify(jsondata,null,4)}`);
      return jsondata;
      
    })
 }


function EditClient() {

  const location = useLocation();
  const navigate = useNavigate();
  const {token,setToken} = useToken();
  //alert(`77: AddCase() token= ${JSON.stringify(token)}`);

  if(!token){
    //alert('80: AddCase() !token');
    return (<Navigate to="/authentication/sign-in" />);
  }
  
  //const [inputs,setInputs] = useState({isloading:true,data:{}});
  const [init,setInit] = useState({done:false});
  const [inputs,setInputs] = useState({});

  

useEffect(() => {
  //alert('52: useEffect');
  const post_data = { 
    session:{sessionId: token.token}, 
    data:{id:location.state.id}
  };
  fetch(`${appconfig.api_root}/clients/startedit`
  , {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(post_data)
    
  }
  )
    .then((res) => {
      //alert(`32: res= ${JSON.stringify(res,null,4)}`);
      return res.json();
    })
    .then((jsondata) => {
      //alert(`140: ${JSON.stringify(jsondata,null,4)}`);
      setInit({done: true});
      setInputs(jsondata.client);
      
    });
}, []);

if(!init.done){
  return (
   <DashboardLayout>
     <DashboardNavbar />
     <MDBox pt={6} pb={3}>
       <p>Loading...</p>
     </MDBox>
     
     <Footer />
   </DashboardLayout>
  );
}




const handleChange = (event) => {
  const name = event.target.name;
  const value = event.target.value;
  setInputs(values => ({...values, [name]: value}))
}


const handleUpdate = async e => {
   //alert('226: handleSubmit');
   e.preventDefault();
   //setInputs({isloading:false, data:input})
   
   const ret = await updateData(inputs,token.token);
   //alert(`138: handleSubmit() retAddReview= ${JSON.stringify(ret)}`);
  //alert(`232: handleUpdate ret= ${JSON.stringify(ret,null,4)}`);
  if(ret && ret.id){
    //alert('142: goto /reviews');
    navigate('/clients');
  }
  else{
    //alert('146: goto nowhere');
  }
   //setToken(token);
  
  
   
};

const handleDelete = async e => {
  //alert('226: handleSubmit');
  e.preventDefault();
  //setInputs({isloading:false, data:input})
  
  const ret = await deleteData(inputs,token.token);
  //alert(`180: handleDelete() retAddReview= ${JSON.stringify(ret)}`);
 //alert(`232: handleSubmit ret= ${JSON.stringify(ret,null,4)}`);
 if(ret && ret.id){
   //alert('142: goto /reviews');
   navigate('/clients');
 }
 else{
   alert('Something went wrong.');
 }
  //setToken(token);
 
 
  
};


//useEffect( () => {setInputs(init.data);},[]);

//alert(`243: inputs= ${JSON.stringify(inputs)}`);




  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
    
      <MDBox component="form" role="form" mt={8}>
        
        <MDBox mb={6}>
          <Grid container spacing={1}>
                
                <Grid item container item spacing={3}>
                    
                    
                    <React.Fragment>
                        <Grid item xs={4}>
                          <Item><MDInput id="name" name="name"   type="text" variant="outlined" label="Name" variant="standard" onChange={handleChange} value={inputs.name} /></Item>
                        
                        </Grid>
                        
                        
                        
                    </React.Fragment>
                    
                        
                </Grid>        
                      
                      
                
                
                
                
          </Grid>
          
        </MDBox>
        

        <MDBox mb={6}>
          <RadioGroup
            aria-labelledby="status-radio-buttons-group-label"
            value={inputs.status}
            onChange={handleChange}
            name="status"
            row
          >
            <FormControlLabel value="1" control={<Radio />} checked={inputs.status === 1} label="ACTIVE" />
            
            <FormControlLabel value="0" control={<Radio />} checked={inputs.status === 0} label="IN-ACTIVE" />
            
          </RadioGroup>
        </MDBox>

        <MDBox mb={1}>
          <Grid container spacing={0.5}>
              
              <Grid item>
                  <MDButton
                    color="dark"
                    variant="gradient"
                    onClick={handleUpdate}
                  >
                    Update
                  </MDButton>
              </Grid>
              <Grid item>
                  <MDButton
                    color="dark"
                    variant="gradient"
                    onClick={handleDelete}
                  >
                    Delete
                  </MDButton>
              </Grid>
              
          </Grid>
        </MDBox>

        


      </MDBox>
    
      
      <Footer />
    </DashboardLayout>
  );
}

export default EditClient;
