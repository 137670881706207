import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

//ROM
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import appconfig from "appconfig";
import useToken from 'useToken';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

 async function submitData(input_data, token) {
  //alert(`55: submitData(input_data= ${JSON.stringify(input_data)} token= ${JSON.stringify(token)})`);
  const post_data = { session:{sessionId: token}, data:input_data}
  //alert(`57: post_data= ${JSON.stringify(post_data,null,4)}`)
  return fetch(`${appconfig.api_root}/clients/postadd`
  , {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(post_data)
    
  }
  )
    .then(data => {
      
      return data.json();
    })
 }


function AddClient() {
  const navigate = useNavigate();
  const {token,setToken} = useToken();
  //alert(`77: AddCase() token= ${JSON.stringify(token)}`);

  if(!token){
    //alert('80: AddCase() !token');
    return (<Navigate to="/authentication/sign-in" />);
  }
  /*const sxtf = {
    input: {
      
      background: "white"
    }
  };
*/
const default_data = {
  //isloading:true,
  //data:{
    //status: 0,
    //id:'',
    name: '',
    //status:0,

    
};

const [inputs,setInputs] = useState(default_data);
  
  
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }


  const handleSubmit = async e => {
     //alert('handleSubmit');
     e.preventDefault();
     const ret = await submitData(inputs,token.token);
     //alert(`138: handleSubmit() retAddReview= ${JSON.stringify(ret)}`);
    if(ret && ret.id){
      //alert('142: goto /reviews');
      navigate('/clients');
    }
    else{
      alert('146: goto nowhere');
    }
     //setToken(token);
    
    
     
  };

  const handleClearAll = () => setInputs(default_data);
  

  

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
    
      <MDBox component="form" role="form" mt={8}>
        
        <MDBox mb={6}>
          <Grid container spacing={1}>
                
                <Grid item container item spacing={3}>
                    
                    
                    <React.Fragment>
                        <Grid item xs={4}>
                          <Item><MDInput id="name" name="name"   type="text" variant="outlined" label="Name" variant="standard" onChange={handleChange} value={inputs.name} /></Item>
                        
                        </Grid>
                        {/*}
                        <Grid item xs={4}>
                          <Item><MDInput id="id" name="id"   type="text" variant="outlined" label="ID" variant="standard" onChange={handleChange} value={inputs.id} /></Item>
                        
                        </Grid>
                      */}
                        
                        
                    </React.Fragment>
                    
                        
                </Grid>        
                
                
          </Grid>
          
        </MDBox>
        
        {/*
        <MDBox mb={6}>
          <RadioGroup
            aria-labelledby="role-radio-buttons-group-label"
            value={inputs.roleId}
            onChange={handleChange}
            name="roleId"
            row
          >
            <FormControlLabel value="prep" control={<Radio />} checked={inputs.roleId === 'prep'} label="PREP" />
            
            <FormControlLabel value="fl" control={<Radio />} checked={inputs.roleId === 'fl'} label="FL" />
            <FormControlLabel value="sl" control={<Radio />} checked={inputs.roleId === 'sl'} label="SL" />
            <FormControlLabel value="qa" control={<Radio />} checked={inputs.roleId === 'qa'} label="QA" />
            <FormControlLabel value="admin" control={<Radio />} checked={inputs.roleId === 'admin'} label="ADMIN" />
          </RadioGroup>
        </MDBox>
        */}

        {/*
        <MDBox mb={6}>
          <RadioGroup
            aria-labelledby="status-radio-buttons-group-label"
            value={inputs.status}
            onChange={handleChange}
            name="status"
            row
          >
            <FormControlLabel value="1" control={<Radio />} checked={inputs.status === 1} label="ACTIVE" />
            
            <FormControlLabel value="0" control={<Radio />} checked={inputs.status === 0} label="IN-ACTIVE" />
            
          </RadioGroup>
        </MDBox>
        */}

        <MDBox mb={1}>
          <Grid container spacing={0.5}>
              
              <Grid item>
                  <MDButton
                    color="dark"
                    variant="gradient"
                    onClick={handleSubmit}
                  >
                    Submit
                  </MDButton>
              </Grid>
              
              
              
          </Grid>
        </MDBox>

        


      </MDBox>
    
      
      <Footer />
    </DashboardLayout>
  );
}

export default AddClient
