

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";

// Images
import LogoAsana from "assets/images/small-logos/logo-asana.svg";
import logoGithub from "assets/images/small-logos/github.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoInvesion from "assets/images/small-logos/logo-invision.svg";





export default function data(clients, navigate) {
  //alert('LINE 26:');
  //ROM
  //const [cases,setCases] = useState([]); 
  

  
  

  const Client = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" variant="rounded" />
      <MDTypography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );
/*
  const Progress = ({ color, value }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {value}%
      </MDTypography>
      <MDBox ml={0.5} width="9rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  );

  */

  //alert(`99: cases= ${JSON.stringify(cases,null,4)}`);

  const rows = clients.map((item) => {
    //let caseID = item.id;
    /*
    let progress = 10;
    switch(item.level){
      case 'PREP':
        progress = 10;
        break;
      case 'ML':
        progress = 20;
        break;
      case 'FL':
        progress = 30;
        break;

      case 'SL':
        progress = 60;
        break;
      
      case 'QA':
        progress = 60;
        break;
      
      case 'RL':
        progress = 100;
        break;

    }
        */
    return {
      name: <Client name={item.name} />,
      
      
      id: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item.id}
        </MDTypography>
      ),
      
      
      status: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item.status}
        </MDTypography>
      ),
      
      action: (
        
        <div>
          <MDTypography component="a" href="#"  color="text" onClick={(e) => {e.preventDefault(); navigate('/clients/edit',{state:{id:item.id}}); }}>
          <Icon>edit</Icon>
        </MDTypography>
        
        </div>
        
      ),
    }

  });

  return {
    columns: [
      { Header: "name", accessor: "name", width: "30%", align: "left" },
      { Header: "id", accessor: "id", align: "left" },
      //{ Header: "role", accessor: "role", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      
      { Header: "action", accessor: "action", align: "center" },
    ],


    
   rows: rows
    
   
    
  };
}
